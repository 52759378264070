/* /mask-twotixx-box-office-desktop.svg */


.inner-hero-banner-sec{
    background:linear-gradient(169deg, #0057ff 15%, #0046cd 100%);
    min-height:475px;
    position:relative;
    /* overflow: hidden; */
    text-align:center;
    padding:0 16px;
}
.inner-hero-banner-sec:before{
    position:absolute;
    content:"";
    top:0;
    left:0;
    width:18%;
    height:100%;
    background:url('../../../public/left-box-pattern-banner.svg') no-repeat;
    background-size:contain;
    background-position:left top;
}
.inner-hero-banner-sec:after{
    position:absolute;
    content:"";
    bottom:0;
    right:0;
    width:18%;
    height:100%;
    background:url('../../../public/right-box-pattern-banner.svg') no-repeat;
    background-size:contain;
    background-position:right bottom;
}

.inner-hero-banner-sec .container{
    max-width:890px;
    display:block;  
    margin:0 auto;
    padding:5.5rem 0 0;
    position: relative;
    z-index:1;
}
.inner-hero-banner-sec .banner-tittle{
    font-size:64px;
    color:#fff;
    font-weight:600;
    margin:0;
}

.inner-hero-banner-sec p{
    font-size:24px;
    font-weight:500;
    line-height:32px;
    color: #fff;
    max-width:668px;
    margin:15px auto 0;
}

.circleShape {
    width: 100%;
    position: absolute;
    left:0;
    bottom: -1px;
}


.ensuring-safe-sec{
    background:#fff;
    padding:25px 16px 0;
}

.ensuring-safe-sec .container{
    max-width:1076px;
   
    margin:0 auto;
}

.ensuring-safe-inner{
    display:flex;
    flex-wrap:wrap;
}
.ensuring-safe-lft{
    width:45%;
    padding:1.5rem 0 0;
}
.ensuring-safe-rht{
    width:55%;
}

.ensuring-safe-lft .tittle {
    font-size: 56px;
    font-weight: 600;
    color: #002366;
    line-height: 72px;
    margin: 1.6rem 0 0;
}
.ensuring-safe-lft .tittle span{
    color:#0057FF;
}

.ensuring-safe-lft p{
    color:#002366;
    font-size:20px;
    font-weight:500;
    line-height:28px;
}

.ticktGuard-work-sec{
    background:#F1F2F4;
    padding:60px 16px 80px 16px;
}

.ticktGuard-work-sec .container{
    max-width:1300px;
    margin:0 auto;
    display: block;
}

.ticktGuard-work-sec .tittle{
    color:#002366;
    font-weight:600;
    font-size:40px;
    line-height:56px;
    text-align:center;
}

.ticktGuard-work-items {
    display: flex;
    flex-wrap: wrap;
    padding: 11px 0 0;
    column-gap: 1.8%;
    row-gap:25px;
}
.ticktGuard-work-item-box {
    border: 1px solid #DCDFE4;
    border-radius: 40px;
    background: #FFFFFF;
    width: 23.6%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 22px;
}
.ticktGuard-verify-item-title {
    align-self: stretch;
    color: var(--Primary-Mid-Blue);
text-align: center;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px;
margin-top: 22px;
}
.work-icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #0057FF;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticktGuard-work-item-box .badge {
   
    border-radius: 50px;
    font-weight: 600;
    font-size: 16px;
    padding: 6px 12px;
    margin:22px 0 0;
}
.ticktGuard-work-item-box .badge.blue{
    background:#E5EEFF;
    color:#0057FF;
}
.ticktGuard-work-item-box .badge.green{
    background:#E6F2E6;
    color:#097E07;
}

.work-tittle {
    color: #002366;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    margin:19px 0 15px;
}

.verify-title {
    margin-top: 8px;
}

.ticktGuard-work-item-box p{
    color:#44546F;
    font-size:14px;
    font-weight:500;
    line-height:20px;
    margin:19px 0 0;
}

.servey-text-content-container {
    margin: 0 auto;
    padding: 40px 48px 40px 48px;
    z-index: 1;
    background-color: var(--Primary-Mid-Blue);
  }

  .servey-text-label-container {
    max-width: 856px;
    margin: 0 auto;
  }

  .servey-text-ticket-guard {
    font-size: 32px;
    font-weight: 500;
    color: var(--Twotixx-Text-Ice-White);
    line-height: 40px;
    text-align:center;
    max-width:856px;
    display:block;
  }

  .highlight-text {
    color: yellow; /* You can also use a hex code or RGB value for a specific shade of yellow */
}

.supporting-organizer-content-container {
    margin: 0 auto;
    padding: 60px 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    gap: 24px;
}

.supporting-organizer-item{
    max-width:526px;
    width:35%;
}
.supporting-organizer-item .ticket-guard-card-organizer-container{
    width:100%;
    height:auto;
    background-size:cover;
}
img.mobile-img{
    display:none;
}


@media(max-width:1024px){

    .ticktGuard-work-item-box{
        width:49.1%;
    }
    .ticktGuard-work-items{
        row-gap:11px;
    }

    .supporting-organizer-item{
        width:48%;
    }

}


@media(max-width:767px){

    img.desktop-img{
        display:none;
    }
    img.mobile-img{
        display:block;
    }
    .inner-hero-banner-sec .banner-tittle{
        font-size:48px;
        line-height:56px;
    }
    .inner-hero-banner-sec:before, .inner-hero-banner-sec:after{
        width:45%;
    }
    .inner-hero-banner-sec p{
        font-size:20px;
        line-height:28px;
        margin:15px auto 0;
    }

    .inner-hero-banner-sec {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .inner-hero-banner-sec .container {
        padding: 1rem 0 2rem;
    }

    .ensuring-safe-lft{
        width:100%;
    }
    .ensuring-safe-rht{
        width:100%;
        padding:40px 0 0;
    }
    .ensuring-safe-rht .img-fluid {
       width:98%;       
       margin:0 auto;
    }
    .ensuring-safe-lft p{
        margin:11px 0 0;
    }

    .supporting-organizer-content-container{
        flex-wrap:wrap;
    }
    .supporting-organizer-item{
        width:100%;
    }

    .supporting-organizer-content-container{
        gap:50px;
    }


}




@media(max-width:575px){

    .inner-hero-banner-sec{
        min-height:400px;
    }
    .inner-hero-banner-sec:before, .inner-hero-banner-sec:after{
        width:75%;
    }

    .inner-hero-banner-sec p{
        margin:22px auto 0;
    }

    .ensuring-safe-lft .tittle{
        font-size:32px;
        line-height:40px;
    }

    .img-divide{
        display:none;
    }

    .ensuring-safe-lft p{
        font-size:16px;
        line-height:24px;
        margin:30px 0 0;
    }

    .ticktGuard-work-sec .tittle{
        font-size:32px;
        line-height:40px;
    }
    .ticktGuard-work-sec {        
        padding: 29px 16px 50px 16px;
    }
    .ticktGuard-work-item-box {
        width: 100%;
    }
    .ticktGuard-work-items {
        row-gap: 25px;
    }

    .servey-text-ticket-guard{
        font-size:24px;
        line-height:32px;
    }
    .servey-text-content-container {       
        padding: 40px 16px;    
       
    }
    .work-tittle{
        font-size:20px;
        line-height:28px;
    }
    .supporting-organizer-item .ticket-guard-card-organizer-container-text1{
        font-size:32px;
        line-height:32px;
    }
    .supporting-organizer-item .ticket-guard-card-organizer-container-text2{
        font-size:40px;
        line-height:56px;
        margin-top:2px;
    }
    .supporting-organizer-item .ticket-guard-card-organizer-container-text3{
        font-size:14px;
        line-height:20px;
    }

}