.content-icon1 {
  position: relative;
  border-radius: var(--br-9xs);
  width: var(--padding-128px);
  height: var(--padding-72px);
  object-fit: cover;
  aspect-ratio: 16/9;
  
}
.content-icon1.cont-payment {
  width: var(--full-width);
  height: auto;
}
.text24 {
  color: var(--text-heading-primary);
  align-self: stretch;
  position: relative;
  font-size: var(--padding-13xl);
  line-height: var(--padding-28px);
  /* text-transform: uppercase; */
  font-weight: var(--font-w-600);
  font-family: var(--global-font-family);
  margin: 0 0 20px;
}
.calendar-icon2 {
  position: relative;
  width: var(--padding-xl);
  height: var(--padding-xl);
  overflow: hidden;
  flex-shrink: 0;
}
.supporting-text27 {
  flex: 1;
  color: rgba(0, 35, 102, 1);
  position: relative;
  line-height: var(--padding-xl);
  font-size: 20px;
  font-weight: var(--font-w-500);
}
.calendar-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: flex-start;
  gap: var(--gap-5xs);
  margin: 0 0 24px;
}
.text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
  margin: 0px 0 0 19px;
}
.content24{
  display: flex;
  gap: 48px !important;
  justify-content: center;
}
.modal-header {
  align-self: stretch;
  background-color: var(--monochrome-white);
  display: flex;
  justify-content: flex-start;
}
.content24 {
  flex-direction: row;
  align-items: flex-start;
  gap: var(--gap-base);
}
.modal-header {
  width: 100%;
  display: inline-block;
  text-align: left;
  margin-bottom: 16px;
  font-size: var(--body-sm-size);
  color: var(--monochrome-black);
  font-family: var(--global-font-family);
}
.calendar-container:last-child{
  margin-bottom: 0 !important;
}

@media (max-width: 768px) {
  .supporting-text27{
    font-size: 16px;
  }
  .content24{
    gap: 0 !important;
  }
  .removeInfo{
    padding: 0 !important;
    border: 0 !important;
    box-shadow: 0px 0px !important;
  }
  .modal-content .modal-header .content24 .text-and-supporting-text1{
    order: -1;
  }
}