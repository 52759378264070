.countdown-timer {
  position: relative;
  margin-left: auto;
  margin-right: 25px;
}

.time-text {
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 500;
  color:#0057FF;
}
