.terms-service-main .header {
  z-index: 99999;
}
.home-banner {
  background: var(--Twotixx-Background-Ice-White) url(../../../public/maskTopHome.svg) no-repeat right top -85px;
  position: relative;
  display: block;
  z-index: inherit;
  height: auto;
  /* padding: var(--padding-8xl) var(--padding-182px); */
  padding: var(--padding-8xl) var(--padding-0);
}
.home-banner::before {
  content: "";
  /* background: url(../../../public/maskTopHome.svg) no-repeat right bottom/100% 100%;
  position: absolute;
  right: -248px;
  top: -73.47px;
  width: 100%;
  height: 97%; */
}
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  text-align: left;
  width: var(--full-width);
  z-index: 9999;
}
.home-banner .header-content{
  max-width:1091px;
  margin:0 auto;
  padding:0 15px;
}
.header-content-lft .bannerTitle {
  font-size: var(--padding-8xl);
  line-height: 1.25;
  font-weight: var(--font-w-600);
  margin: var(--padding-0);
  color: var(--Primary-Mid-Blue);
}
.header-content-lft .bannerTitle span {
  display: inline-block;
  background: linear-gradient(93.2deg, #0057FF 2.65%, #CC006E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-content-lft .bannerP {
  font-size: var(--gap-5xl);
  color: #002366;
  font-weight: 500;
  line-height: 1.34;
  margin: 48px 0 40px 0;
}
.header-content-lft {
  color: var(--monochrome-white);
  flex: 1 0 0;
  margin-right: 24px;
}
.header-content-rht {
  flex: 526px 0 0;
  max-width: 50%;
}
.header-content-rht .video-fluid {
  border-radius: 8px;
  box-shadow: var(--shadow-common);
  display: block;
  width: var(--full-width);
}
.loader-video {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.mission-game-right-mask-background {
  /* background: url(../../../public/maskTopHome.svg) no-repeat right top -100px/100% 100%; */
  background-color: rgb(163, 204, 27);
  background: url(../../assets/svgs/maskMiddleRightHome.svg) no-repeat right top -345px/31% 104% !important;
  padding-top: 80px;
  margin-top: 0px;
}
.home-banner::after {
  content: "";
  position: absolute;
  top: -183.47px;
  right: 0px;
  width: 1068px;
  height: 1068px;
  border-radius: 1068px;
  /* opacity: 0.3; */
  /* background: var(--Twotixx-Secondary-Fuchsia); */
  /* filter: blur(150px); */
}
.pointerCursor {
  cursor: pointer;
}
.event-img {
  width: 100%;
  display: block;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
}
h6.MuiTypography-root.MuiTypography-subtitle1.css-faxm3s-MuiTypography-root {
  color: rgba(0, 35, 102, 1);
}
.header-trapBg {
  min-height: 432px;
  background: url("../../../public/banner-tranp-bg.png");
  background-repeat: no-repeat;
  background-size: var(--full-width) var(--full-width);
  position: relative;
  margin-top: -432px;
  z-index: 999;
  width: var(--full-width);
}
.img-fluid {
  max-width: var(--full-width);
  height: auto;
}
.btn {
  background: var(--Primary-Mid-Blue);
  border-radius: var(--br-5xs);
  font-size: var(--body-md-size);
  color: var(--monochrome-white);
  font-weight: var(--font-w-500);
  text-decoration: none;
  padding: var(--padding-md) var(--padding-9xl);
  display: inline-block;
}
.section.tending-events-sec {
  padding: var(--padding-8xl) 3%;
  gap: 48px;
}
.tending-events-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--full-width);
}
.tending-events-title h2 {
  color: var(--twotixx-text-midnight-blue);
  font-weight: var(--font-w-600);
  font-size: var(--display-lg-md);
  line-height: 1.4em;
  margin: var(--padding-0);
}
.new-home-bodyContent-sec {
  background: var(--twotixx-white--Twotixx-Background-Ice-White);
}
.new-home-bodyContent-inner-top {
  display: flex;
  flex-wrap: wrap;
  width: var(--full-width);
  text-align: left;
  align-items: center;
  padding-left: 10%;
  background: url(../../../public/maskMiddleHome.svg) no-repeat left bottom 0px/17% 100%;
}
.new-home-bodyContent-inner {
  display: flex;
  flex-wrap: wrap;
  width: var(--full-width);
  text-align: left;
  align-items: center;
  padding-left: 10%;
}
.new-home-bodyContent-lft {
  width: var(--half-width);
}
.new-home-bodyContent-lft .img-fluid {
  width: var(--full-width);
}
.new-home-bodyContent-rht {
  width: var(--half-width);
  padding: 0 0 0 var(--padding-57px);
}
.body-mission-game-sec .new-home-bodyContent-rht {
  padding: 0px;
}
.new-home-bodyContent-rht h2 {
  font-weight: var(--font-w-600);
  font-size: var(--display-lg-md);
  color: var(--twotixx-text-midnight-blue);
  line-height: var(--line-hight-md);
  margin: var(--gap-13xl) 0 0;
}
.new-home-bodyContent-rht p {
  font-size: var(--body-xl-size);
  font-weight: var(--font-w-500);
  line-height: var(--line-hight-sm);
  color: var(--twotixx-text-midnight-blue);
  margin: var(--gap-5xl) 0 0;
}
.body-mission-game-sec .new-home-bodyContent-rht {
  order: 1;
}
.body-mission-game-sec .new-home-bodyContent-lft {
  order: 2;
}
.new-home-bodyContent-rht h2 span {
  color: var(--Primary-Mid-Blue);
}
.started-our-appSec.section {
  /* padding: 0 72px 64px 72px; */
  padding: 0 15px 64px 15px;
  position: relative;
  gap: unset;
}
.started-our-appSec::before {
  background-color: #0057FF;
  border-radius: var(--half-width);
  content: "";
  filter: blur(355px);  
  position: absolute;
  left: -355px;
  bottom: -355px;
  opacity: 0.1;
  height: 710px;
  width: 710px;
}
.started-our-appSec::after {
  background-color: #B115D1;
  border-radius: var(--half-width);
  content: "";
  filter: blur(355px);  
  position: absolute;
  right: -355px;
  bottom: -355px;
  opacity: 0.1;
  height: 710px;
  width: 710px;
}
.started-our-appSec .appSecContainer {
  background: var(--monochrome-white);
  border: 1px solid #DCDFE4;
  /* border-radius: var(--br-5xs); */
  border-radius: 24px;
  /* box-shadow: 0px 0.37px 3.148px 0px rgba(0, 0, 0, 0.01), 0px 1.63px 6.519px 0px rgba(0, 0, 0, 0.02), 0px 4px 13px 0px rgba(0, 0, 0, 0.02), 0px 7.704px 25.481px 0px rgba(0, 0, 0, 0.02), 0px 12.963px 46.852px 0px rgba(0, 0, 0, 0.03), 0px 20px 80px 0px rgba(0, 0, 0, 0.04); */
  box-shadow: 0px 0.37px 3.15px 0px #00000002, 0px 1.63px 6.52px 0px #00000004, 0px 4px 13px 0px #00000005, 0px 7.7px 25.48px 0px #00000006, 0px 12.96px 46.85px 0px #00000008, 0px 20px 80px 0px #0000000A;
  position: relative;
  max-width:1296px;
  width: 100%;
  z-index: 1;
  overflow:hidden;
  /* padding: 25px; */
}

.started-our-app-inner {
  display: flex;
  flex-wrap: wrap;
  width: var(--full-width);
  text-align: left;
  align-items: center;
}
.started-our-app-lft {
  width: var(--half-width);
  text-align: center;
  align-self: flex-end;
  overflow: hidden;
}
.started-our-app-lft .bannerGetStarted {
  display: inline-block;
  margin-bottom: -218px;
  margin-left: -230px;
}
.started-our-app-lft .img-fluid.iPhone-mobile-desktop {
  display: block;
  margin:0 0 -8px 17px;
}
.started-our-app-rht {
  padding: 84px 72px 84px 0;
  width: var(--half-width);
}
.started-our-app-rht .iconfooterLogo {
  margin-bottom: 24px;
  width: 56px;
}
.started-our-app-rht .titleGetStarted {
  font-weight: var(--font-w-600);
  font-size: var(--display-lg-md);
  /* color: var(--twotixx-text-midnight-blue); */
  color: #002366;
  /* line-height: var(--line-hight-md); */
  line-height: 1.4;
  /* margin: var(--gap-13xl) 0 0; */
  margin: 0 0 24px 0;
}
.started-our-app-rht .titleGetStarted span {
  color: var(--Primary-Mid-Blue);
}
.started-our-app-rht .pGetStarted {
  /* font-size: var(--body-xl-size); */
  font-size: 16px;
  font-weight: var(--font-w-500);
  /* line-height: var(--line-hight-sm); */
  line-height: 1.5;
  /* color: var(--twotixx-text-midnight-blue); */
  color: #002366;
  /* margin: var(--gap-5xl) 0 0; */
  margin: 0 0 32px 0;
}
.started-our-app-rht .simple-button {
  /* margin: var(--gap-13xl) 0 0; */
}
.selling-online-sec {
  background: url("../../../public/lee-blanchflower.jpg") no-repeat;
  min-height: 640px;
  position: relative;
  background-size: cover;
  background-position: center;
}
.selling-online-sec.section {
  padding: 0px;
}
.selling-online-sec .container {
  position: relative;
  z-index: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.selling-online-sec:after {
  position: absolute;
  content: "";
  width: var(--full-width);
  height: var(--full-width);
  background: var(--twotixx-text-midnight-blue);
  top: 0;
  left: 0;
  opacity: 0.70;
}
.selling-online-sec h3 {
  font-size: var(--body-xl-64);
  color: var(--monochrome-white);
  line-height: var(--line-hight-80);
  font-weight: var(--font-w-600);
  margin: 0;
}
.selling-online-sec h3 span {
  display: block;
  color: var(--Primary-Mid-Blue);
}
.events-scroll-container {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 20px;
  padding: 0px 0;
  width: var(--full-width);
  scrollbar-width: none;
  -ms-overflow-style: none;
  white-space: nowrap;
}
.events-scroll-container::-webkit-scrollbar {
  display: none;
}
.event-card {
  flex: 0 0 auto;
  width: 200px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.event-card img {
  width: var(--full-width);
  display: block;
}
.event-info {
  padding: 10px;
}
.view-all-button {
  background: none;
  border: none;
  color: var(--Primary-Mid-Blue);
  cursor: pointer;
}
.eventsViewAllBtn-mobile,
.iPhone-mobile-mobile {
  display: none;
}
.homeTrendingEventsSection {
  gap: unset!important;
  padding: 4px 0 80px 0!important;
}
.homeTrendingEventsSection .tending-events-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 72px;
  margin-bottom: 32px;
}
.homeTrendingEventsSection .tending-events-title h2 {
  flex: 1 0 0;
  font-size: 32px;
  line-height: 1.25;
  font-weight: 600;
}
.homeTrendingEventsSection .tending-events-title .simple-button {
  flex: auto 0 0;
  border: none !important;
  margin-left: 24px;
}
.homeTrendingEventsSlider {
  display: flex!important;
  flex-wrap: nowrap!important;
  gap: unset!important;
  justify-content: space-between!important;
  padding-left: 72px!important;
}
.homeTrendingEventsSlider .MuiBox-root {
  gap: unset;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root {
  margin: 0 12px;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root .event-img {
  border-radius: 16px;
  height: 172px;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root .boxTextCont > .MuiBox-root {
  margin-top: 16px;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root .boxTextCont > .MuiBox-root h6 {
  color: #002366;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding-top: 0;
  margin-bottom: 16px;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root .boxTextCont > .MuiBox-root > span {
  color: #44546F!important;
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 1.5!important;
}
.homeTrendingEventsSlider .MuiBox-root .MuiPaper-root .boxTextCont > .MuiBox-root > p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 12px;
}
.homeBoxContImgLeft {
  /* padding: 19px 126px 77px 53px; */
  padding: 19px 0 77px 0;
}
.homeBoxContImgLeft .boxContImgLeftBody {
  /* background: url(../../../public/maskMiddleHome.svg) no-repeat left bottom 84px; */
  background:none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 185px; */
  max-width:1091px;
  margin:0 auto;
  padding:0 15px;
}
.homeBoxContImgLeft .boxContImgLeftBody .imgContLeft {
  box-shadow: var(--shadow-common);
  border-radius: 24px;
  flex: 526px 0 0;
  max-width:48%;
  
  position:relative;
}
.homeBoxContImgLeft .boxContImgLeftBody .imgContLeft:after{
  content:"";
  background: url(../../../public/elevatingEventSecurity.svg) no-repeat;
  background-size: contain;
  width:302px;
  height:290px;
  position: absolute;
  bottom:12%;
  left:-160px;
}

.homeBoxContImgLeft .boxContImgLeftBody .imgContLeft svg {
  display: block;
  height: auto;
  max-width: 100%;
  position: relative;
  z-index:1;
}
.homeBoxContImgLeft .boxContImgLeftBody .textContRight {
  flex: 1 0 0;
  /* margin-left: 64px; */
  padding:0 0 0 62px;
}
.homeBoxContImgLeft .boxContImgLeftBody .textContRight .imgTicketGuard {
  margin-bottom: 48px;
}
.homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContTitle {
  color: #002366;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 32px 0;
}
.homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContP {
  color: #002366;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}
.terms-service-main {
  overflow-x: hidden;
}
.homeBoxContImgRight {
  background-image: none!important;
  /* padding: 0 0 64px 206px; */
  padding: 0 0 64px 0;
  gap: unset;
}
.homeBoxContImgRight .boxContImgRightBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 0; */
  /* padding-right: 182px; */
  max-width:1091px;
  padding:0 15px;
  margin:0 auto;
}
.homeBoxContImgRight .boxContImgRightBody .textContLeft {
  flex: 1 0 0;
  /* margin-right: 40px; */
  padding:0 62px 0 0; 
}
.homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContTitle {
  color: #002366;
  font-size: 40px;
  font-weight: 600;
  line-height: 1.4;
  margin: 0 0 32px 0;
}
.homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContTitle span {
  display: block;
}
.homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContP {
  color: #002366;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}
.homeBoxContImgRight .boxContImgRightBody .imgContRight {
  box-shadow: var(--shadow-common);
  border-radius: 24px;
  flex: 526px 0 0;
  max-width: 48%;
  position: relative;
  z-index: 1;
}
.homeBoxContImgRight .boxContImgRightBody .imgContRight::after {
  background: url(../../assets/svgs/maskMiddleRightHome.svg) no-repeat right top;
  content: "";
  height: 528px;
  width: 476px;
  position: absolute;
  right: -185px;
  top: 395px;
  z-index: 2;
}
.homeBoxContImgRight .boxContImgRightBody .imgContRight svg {
  display: block;
  height: auto;
  width: 526px;
  max-width: 100%;
}


/* css start 13-09-24 */

.mask-twotixx-box-officeImg-mobile{
  display:none;
}
.home-parg-btn {
  margin: 29px 0 0;
}
/* css end 13-09-24 */


@media (min-width: 1200px) and (max-width: 1366px) {
  .home-banner {
    background-position: right -140px top -80px;
    padding: 80px 9.5%;
  }
  .homeTrendingEventsSection .tending-events-title {
    padding: 0 9.5%;
  }
  .started-our-app-rht .titleGetStarted {
    font-size: 36px;
  }
  .started-our-appSec.section {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .home-banner {
    background-position: right -160px top -100px;
    padding: 60px 5%;
  }
  .homeTrendingEventsSection .tending-events-title {
    padding: 0 5%;
  }
  .started-our-app-rht {
    padding: 30px 30px 30px 0;
  }
  .started-our-app-rht .iconfooterLogo {
    margin-bottom: 10px;
  }
  .started-our-app-rht .titleGetStarted {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .started-our-app-rht .pGetStarted {
    margin-bottom: 20px;
  }
  .started-our-appSec.section {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 1199px) {

  /* .homeBoxContImgLeft .boxContImgLeftBody .textContRight {
    margin-left: 30px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft {
    margin-right: 30px;
  } */

}
@media (min-width: 768px) and (max-width: 991px) {
  .home-banner {
    background-position: right -100px top;
    background-size: 70% auto;
    padding: 60px 24px;
  }
  .homeTrendingEventsSection .tending-events-title {
    padding: 0 24px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody {
    padding-left: 80px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .imgTicketGuard {
    margin-bottom: 24px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContTitle {
    font-size: 30px;
    margin-bottom: 24px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContP {
    font-size: 18px;
  }
  .homeBoxContImgRight .boxContImgRightBody {
    padding-right: 80px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContTitle {
    font-size: 30px;
    margin-bottom: 24px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContP {
    font-size: 18px;
  }
  .started-our-appSec.section {
    padding-left: 32px;
    padding-right: 32px;
  }
  .started-our-app-rht {
    padding: 20px 20px 20px 0;
  }
  .started-our-app-rht .iconfooterLogo {
    margin-bottom: 4px;
  }
  .started-our-app-rht .titleGetStarted {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .started-our-app-rht .pGetStarted {
    margin-bottom: 20px;
  }

  .homeBoxContImgLeft .boxContImgLeftBody .textContRight{
    padding:0 0 0 52px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft{
    padding:0 52px 0 0;
  }

}


@media (max-width: 1024.98px) {
  .loader-video {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .header-content-lft {
    /* width: 100%; */
  }
  .header-content-rht {
    /* width: 100%; */
  }
  .header-content-lft .bannerTitle {
    font-size: var(--body-xl-64);
    line-height: var(--line-hight-80);
  }
  .section.new-home-bodyContent-sec {
    padding: var(--padding-29xl) 3% 60px;
  }
  .body-mission-game-sec .new-home-bodyContent-rht {
    padding: 0 var(--padding-13xl) 0 0;
  }
  .header-trapBg {
    background-size: 110% 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .loader-video {
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .new-home-bodyContent-rht h2 {
    font-size: 26px;
    line-height: 30px;
  }
  .new-home-bodyContent-rht h2 span {
    white-space: normal;
    word-wrap: break-word;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .home-banner::before {
    background-position: left 0 bottom 20px;
  }
  .home-banner::after {
    top: -850px;
  }
}
@media (max-width: 767px) {
  .home-banner {
    /* background-image: url(http://localhost:3000/static/media/maskTopHomeMobile.e64d9d375ace62a65521.svg); */
    background:var(--Twotixx-Background-Ice-White) url(http://localhost:3000/static/media/maskTopHome.2518577bacc0b14bc230.svg) no-repeat ;
    background-position:right 0% bottom -36%;
    background-size:160%;
    padding: 40px 24px;
  }
  /* .footer-home .footer-d.content5 {
    padding: 30px 15px 0px !important;
  } */
  .home-banner::before {
    background-position: left bottom 144px;
    background-size: cover;
  }
  .home-banner::after {
    /* height: 357px;
    width: 357px;
    top: calc(50% - 187.5px);
    right: -127px; */
  }
  .header-content {
    flex-direction: column;
    /* gap: var(--gap-48); */
  }
  .header-content-lft {
    margin-right: 0;
    margin-bottom: 40px;
    width: 100%;
  }
  .header-content-lft .bannerTitle {
    font-size: var(--display-lg-md);
    line-height: 1.4;
  }
  .header-content-lft .bannerP {
    font-size: 16px;
    line-height: 1.5;
    margin: 24px 0 var(--padding-13xl) 0;
  }
  .header-content-rht {
    position: relative;
    height: auto;
    width: 100%;
    max-width: 100%;
  }
  .loader-video {
    top: -200px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .homeTrendingEventsSection .tending-events-title {
    padding: 0 24px;
  }
  .homeTrendingEventsSection .eventsViewAllBtn-mobile {
    margin-top: 24px;
    padding: 0 24px;
  }
  .tending-events-title h2 {
    font-size: var(--display-md-size-32);
    line-height: var(--line-hight-40);
    font-weight: var(--font-w-600);
  }
  .new-home-bodyContent-lft {
    width: var(--full-width);
  }
  .new-home-bodyContent-rht {
    width: var(--full-width);
  }
  .eventsViewAllBtn-mobile,
  .iPhone-mobile-mobile {
    display: block;
  }
  .tending-events-sec .tending-events-title .simple-button,
  .iPhone-mobile-desktop {
    display: none;
  }
  .section.tending-events-sec {
    padding-top: 0 !important;
  }
  .header-trapBg {
    min-height: 340px;
    margin-top: -340px;
    background: url("../../../public/banner-tranp-bg-mobile.png") no-repeat;
    background-position: bottom right;
    background-size: 140% 73%;
  }
  .eventsViewAllBtn-mobile {
    width: 100%;
  }
  .eventsViewAllBtn-mobile .simple-button {
    font-size: var(--body-md-size) !important;
    width: var(--full-width) !important;
  }
  .section.new-home-bodyContent-sec {
    padding: var(--padding-29xl) var(--body-xl-size) 35px;
  }
  .new-home-bodyContent-rht {
    order: 1;
    padding: 0px;
  }
  .new-home-bodyContent-lft {
    order: 2;
  }
  .new-home-bodyContent-rht h2 {
    font-size: 32px;
    line-height: 30px;
    font-weight: var(--font-w-600);
  }
  .new-home-bodyContent-rht h2 span {
    white-space: normal;
    word-wrap: break-word;
  }
  .new-home-bodyContent-rht h2 span,
  .started-our-app-rht {
    white-space: normal;
    word-wrap: break-word;
  }
  .new-home-bodyContent-rht p,
  .started-our-app-rht .pGetStarted {
    font-size: var(--body-md-size);
    line-height: var(--gap-5xl);
    font-weight: var(--font-w-400);
  }
  .new-home-bodyContent-lft {
    padding: var(--padding-57px) 0 0;
  }
  .started-our-appSec.section {
    /* padding: var(--padding-29xl) var(--body-xl-size) 80px; */
    padding: 40px 24px;
  }
  .started-our-appSec::before {
    left: unset;
    right: -355px;
  }
  .started-our-appSec::after {
    right: unset;
    left: -502px;
    bottom: unset;
    top: 21px;
  }
  .started-our-app-lft {
    width: var(--full-width);
    order: 2;
  }
  .started-our-app-lft .img-fluid.iPhone-mobile-desktop {
    display: none;
  }
  .started-our-app-lft .img-fluid.iPhone-mobile-mobile {
    display: inline-block;
  }
  .started-our-app-rht {
    padding: 40px 24px;
    order: 1;
    width: var(--full-width);
    padding:30px 20px 11px !important;
  }
  .started-our-app-rht .titleGetStarted {
    font-size: 32px;
    line-height: 30px;
    font-weight: var(--font-w-600);
  }
  .selling-online-sec h3 {
    font-size: var(--display-lg-md);
    line-height: var(--line-hight-md);
  }
  .started-our-appSec .container {
    padding: 0px;
  }
  .started-our-app-lft .img-fluid {
    margin: 0px 0 -8px;
    width:100%;
  }
  .started-our-app-rht .simple-button,
  .selling-online-sec .simple-button {
    font-size: var(--body-md-size) !important;
    padding: 9px var(--padding-base) !important;
  }
  .selling-online-sec .simple-button {
    margin: var(--padding-f-s) 0 0;
  }
  .homeTrendingEventsSection {
    padding-bottom: 40px!important;
  }
  .homeBoxContImgLeft {
    padding: 40px 0!important;
  }
  .homeBoxContImgLeft .boxContImgLeftBody {
    background-position: left -44px bottom 71px;
    flex-direction: column;
    padding: 0 24px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .imgContLeft {
    flex: auto;
    order: 2;
    max-width: 100%;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight {
    order: 1;
    margin-left: 0;
    margin-bottom: 32px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .imgTicketGuard {
    margin-bottom: 25px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContTitle {
    font-size: 32px;
    margin-bottom: 32px;
  }
  .homeBoxContImgLeft .boxContImgLeftBody .textContRight .boxContP {
    font-size: 16px;
  }
  .homeBoxContImgRight {
    padding: 40px 0!important;
  }
  .homeBoxContImgRight .boxContImgRightBody {
    flex-direction: column;
    padding: 0 24px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft {
    margin-right: 0;
    margin-bottom: 32px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContTitle {
    font-size: 32px;
    margin-bottom: 32px;
  }
  .homeBoxContImgRight .boxContImgRightBody .textContLeft .boxContP {
    font-size: 16px;
  }
  .homeBoxContImgRight .boxContImgRightBody .imgContRight {
    padding-top: 0;
    flex: unset;
    width: 526px;
    max-width: 100%;
  }
  .homeBoxContImgRight .boxContImgRightBody .imgContRight::after {
    background-size: 100% auto;
    width: 270px;
    right: -104px;
    /* top: 305px; */
    top:40px;
    height:100%;
    background-position:bottom;
  }
    .home-banner .header-content-rht{
      flex:376px 0;
    }

    .home-banner .header-content{padding:0 0;}
    .homeBoxContImgLeft .boxContImgLeftBody .textContRight{padding:0;}
   
    .homeBoxContImgRight .boxContImgRightBody .textContLeft{padding-right:0px;}
    .mask-twotixx-box-officeImg-desktop{
      display:none;
    }

   
    .mask-twotixx-box-officeImg-mobile {
      display: block;
      width: 100%;
      height: 65% !important;
      bottom: -11% !important;
      /* height: auto !important; */
      top: inherit !important;
  }


}


@media screen and (max-width: 575px) {
  .tending-events-title h2 {
    margin-bottom: 30px;
  }
  .tending-events-title {
    flex-flow: row wrap;
  }

  .mask-twotixx-box-officeImg-mobile {
    height: 65% !important;
    bottom: -11% !important;   
  }

}